export default [{
        key: 'overview',
        name: '概况',
        children: [
            { key: 'shop', name: '商城概览', icon: 'icon-RectangleCopy1' },
            // { key: 'goods', name: '商品统计', icon: 'gift' },
            // { key: 'transaction', name: '交易统计', icon: 'gift' },
        ],
    },
    {
        key: 'decoration',
        name: '店铺装修',
        children: [
            { key: 'page', name: '页面装修', icon: 'icon-RectangleCopy' },
        ],
    },
    {
        key: 'goods',
        name: '商品管理',
        children: [
            { key: 'list', name: '全部商品', icon: 'icon-RectangleCopy8' },
            { key: 'sold_out', name: '已售罄商品', icon: 'icon-RectangleCopy11' },
            { key: 'create', name: '添加商品', icon: 'icon-RectangleCopy9' },
            // { key: 'freight_template', name: '运费模板', icon: 'icon-RectangleCopy13' },
            { key: 'category', name: '商品分类', icon: 'icon-RectangleCopy10' },
            { key: 'units', name: '单位管理', icon: 'icon-RectangleCopy14' },
            { key: 'evaluate', name: '评价管理', icon: 'icon-RectangleCopy15' },
            // { key: 'import', name: '商品导入', icon: 'icon-RectangleCopy12' },
        ],
    },
    {
        key: 'order',
        name: '订单管理',
        children: [
            { key: 'list', name: '全部订单', icon: 'icon-RectangleCopy18' },
            { key: 'pending', name: '待发货订单', icon: 'icon-RectangleCopy19' },
            { key: 'return', name: '退货退款订单', icon: 'icon-RectangleCopy16' },
            // { key: 'pick_up', name: '自提订单', icon: 'icon-RectangleCopy17' },
        ],
    },
    {
        key: 'member',
        name: '会员管理',
        children: [
            { key: 'list', name: '会员列表', icon: 'icon-RectangleCopy4' },
            // { key: 'import', name: '会员导入', icon: 'icon-RectangleCopy6' },
            { key: 'card', name: '会员卡', icon: 'icon-RectangleCopy7' },
            { key: 'activateCard', name: '会员审核', icon: 'icon-RectangleCopy20' },
            // { key: 'label', name: '标签管理', icon: 'icon-RectangleCopy5' },
            // { key: 'points', name: '积分管理', icon: 'gift' },
        ],
    },
    {
        key: 'config',
        name: '基础配置',
        children: [
            { key: 'shop', name: '店铺配置', icon: 'icon-RectangleCopy3' },
            // { key: 'transaction', name: '交易配置', icon: 'gift' },
            { key: 'permissions', name: '店铺权限', icon: 'icon-RectangleCopy2' },
            { key: 'freight_template', name: '运费模板', icon: 'icon-RectangleCopy13' },
        ],
    },
]