<template>
  <div v-if="visible">
    <a-result status="404" title="404" sub-title="此页面未找到">
      <template #extra>
        <a-button type="primary" @click="goHome">返回首页</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
  import menus from '@/menus'

  export default {
    name: 'NotFound',
    data() {
      return {
        visible: false,
      }
    },
    methods: {
      initialize() {
        let nodes = this.$route.path.split('/').filter(value => value != '');
        let index = menus.findIndex(item => item.key == nodes[0]);
        if (index != -1) {
          let currentMenu = menus[index].key, currentSubmenu = menus[index].children[0].key;
          this.$router.push(`/${currentMenu}/${currentSubmenu}`);
        } else {
          this.visible = true;
        }
      },
      goHome() {
        this.$router.push('/');
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
</style>